import { createLightTheme, lightThemePrimitives } from "baseui";

export const theme = createLightTheme(
  {
    ...lightThemePrimitives,
    primaryFontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
  },
  {
    borders: {
      inputBorderRadius: "4px",
      buttonBorderRadius: "8px",
    },
    colors: {
      backgroundSecondary: "#f1f1f1",
    },
  }
);
