import React from "react";
import type { AppProps } from "next/app";

import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider } from "baseui";
import { styletron, debug } from "../utils/styletron";
import { Layout } from "../components";
import { theme } from "../theme";
import * as Sentry from "@sentry/node";
import { RewriteFrames } from "@sentry/integrations";
import getConfig from "next/config";

import "./../index.css";
import Head from "next/head";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame?.filename?.replace(distDir, "app:///_next");
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

export default function App({
  Component,
  pageProps,
  err,
}: AppProps & { err: any }) {
  return (
    <>
      <Head>
        <title>Smart Export App</title>
        <meta
          name="description"
          content="Smart Export is a power-up for Trello that enables you to export cards of your boards to multiple formats, including Excel, CSV and JSON."
        />
      </Head>
      <StyletronProvider value={styletron} debug={debug} debugAfterHydration>
        <BaseProvider theme={theme}>
          <Layout>
            <Component {...pageProps} err={err} />
          </Layout>
        </BaseProvider>
      </StyletronProvider>
    </>
  );
}
